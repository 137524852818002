import React, { useState, useEffect, Suspense } from "react";
import "./Dashboard.scss";

import dashboard from "../../Assets/dashboard.svg";
import inventory from "../../Assets/inventory.svg";
import orders from "../../Assets/orders.svg";
import profile from "../../Assets/profile.svg";
import sales from "../../Assets/Overview/sales.svg";
// import orders from "../../Assets/Overview/orders.svg";
import productsicon from "../../Assets/Overview/products.svg";

import { UserContext } from "../../App";
import { useContext } from "react";

import test from "./test";
import Inventory from "../Inventory/Inventory";
import Orders from "../Orders/Orders";
import Profile from "../Profile/Profile";

const Dashboard = () => {
  const Auth = useContext(UserContext);
  const [page, setPage] = useState("Overview");

  console.log(Auth);

  return (
    <>
      {Auth.user.isAuthenticated ? (
        <div className="dashboard">
          <div className="left-panel">
            {/* <a href="/dashboard"> */}
            <button
              autoFocus="true"
              className="a"
              onClick={() => setPage("Overview")}
            >
              <img src={dashboard} alt="dashboard-icon" />
              Dashboard
            </button>
            {/* </a> */}

            <button className="a" onClick={() => setPage("Inventory")}>
              <img src={inventory} alt="inventory-icon" />
              Inventory
            </button>

            <button className="a" onClick={() => setPage("Orders")}>
              <img src={orders} alt="orders-icon" />
              Orders
            </button>
            <button className="a" onClick={() => setPage("Profile")}>
              <img src={profile} alt="orders-icon" />
              Profile
            </button>
          </div>
          <div className="right-panel">
            {page == "Overview" && (
              <div className="overview">
                <div className="header">
                  <h1 className="header-text">Overview</h1>
                </div>
                <div className="opacity">
                  <div className="stat-cards">
                    <div className="card">
                      <img src={sales} alt="sales" />
                      <div className="text">
                        <p className="total">Total Sales</p>
                        <p className="amount">200,000</p>
                      </div>
                    </div>
                    <div className="card">
                      <img src={orders} alt="sales" />
                      <div className="text">
                        <p className="total">Total Orders</p>
                        <p className="amount">301</p>
                      </div>
                    </div>
                    <div className="card">
                      <img src={productsicon} alt="sales" />
                      <div className="text">
                        <p className="total">Total Products</p>
                        <p className="amount">120</p>
                      </div>
                    </div>
                  </div>
                </div>
                <h1 className="soon">Coming soon! 🚀</h1>
              </div>
            )}
            {page == "Inventory" && <Inventory />}
            {page == "Orders" && <Orders />}
            {page == "Profile" && <Profile />}
          </div>
        </div>
      ) : (
        <h1 className="loginpls">Please Log in 🔐</h1>
      )}
    </>
  );
};

export default Dashboard;
